import React, {useEffect, useState} from "react"
import {LoadingPage} from "@indebted/components/Loading"
import {affordabilityAssessmentAPI} from "@indebted/api"
import {ErrorPage} from "@indebted/components/Error"
import {useNavigate, useParams} from "react-router-dom"

import {Steps} from "./Steps"

function Questions() {
	const {secureCode, id} = useParams()
	const navigate = useNavigate()
	const [Component, setComponent] = useState(<LoadingPage />)
	useEffect(() => {
		function onSubmit({answers}) {
			setComponent(<LoadingPage />)
			affordabilityAssessmentAPI
				.submitForm({answers, id, secureCode})
				.then(() => {
					navigate(`/${secureCode}/affordability-assessment/${id}/result`)
				})
				.catch((error) => {
					setComponent(
						<ErrorPage
							title="Failed to submit affordability assessment short form"
							message={error.Message}
							answers={answers}
							error={error}
						/>,
					)
				})
		}

		affordabilityAssessmentAPI
			.findBy({secureCode, id})
			.then((result) => {
				if (result.Status === "NotCompleted") {
					setComponent(<Steps questions={result.Questions} id={result.ID} onSubmit={onSubmit} />)
				} else {
					navigate(`/${secureCode}`)
				}
			})
			.catch((error) => {
				setComponent(
					<ErrorPage
						title="Failed to fetch affordability assessment short form"
						message={error.Message}
						affordabilityAssessmentID={id}
						error={error}
					/>,
				)
			})
	}, [secureCode, id, navigate])

	return Component
}

export {Questions}
