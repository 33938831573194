import {useNavigate, useParams} from "react-router-dom"
import {useEffect} from "react"

function InPaymentPlan({PaymentPlanID}) {
	const navigate = useNavigate()
	const {secureCode} = useParams()

	useEffect(() => {
		navigate(`/${secureCode}/payment-plan-status/${PaymentPlanID}`)
	})
}

export {InPaymentPlan}
