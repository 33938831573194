import React from "react"
import {Page} from "@indebted/components/Page"
import {Button, Typography} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import {useNavigate, useParams} from "react-router-dom"
import {PaymentError} from "@indebted/assets"

function PaymentFailed({I18n}) {
	const {secureCode} = useParams()
	const navigate = useNavigate()
	return (
		<Page>
			<Grid style={{padding: 0}}>
				<PaymentError />
			</Grid>
			<Grid>
				<Typography align="center" variant="h6">
					{I18n.PaymentFailed.Title}
				</Typography>
				<Typography align="center" variant="subtitle1">
					{I18n.PaymentFailed.Message}
				</Typography>
			</Grid>

			<Grid>
				<Button
					onClick={(event) => {
						event.preventDefault()
						navigate(`/${secureCode}`)
					}}
				>
					{I18n.PaymentFailed.RetryButtonText}
				</Button>
			</Grid>
		</Page>
	)
}
export {PaymentFailed}
