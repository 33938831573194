import styled from "styled-components"
import {Box, Card, CardContent, CardHeader, CardProps, Divider, Typography} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import React from "react"

const ColoredTypography = styled(({color, ...props}) => <Typography {...props} />)`
	color: ${(props) => props.color};
`

const DiscountColoredTypography = styled(ColoredTypography)`
	font-size: 14px;
`

const ColoredTypographyWithBottomMargin = styled(ColoredTypography)`
	margin-bottom: 2px;
`

const StyledTypography = styled(ColoredTypography)`
	font-size: 10px;
	font-weight: 600;
	text-transform: uppercase;
`

const CardHeadingTypography = styled(ColoredTypography)`
	font-weight: 500;
`

const DiscountCardHeadingTypography = styled(ColoredTypography)`
	font-weight: 400;
	margin-left: 5px;
`

const PaidAmountTypography = styled(ColoredTypography)`
	font-weight: 600;
	font-size: 32px;
	margin-right: 8px;
`

const CurrencyTypography = styled(ColoredTypography)`
	font-weight: 500;
	font-size: 18px;
	line-height: 36px;
`

const FailedPaymentTypography = styled(ColoredTypography)`
	font-weight: 500;
	line-height: 18px;
`

const StyledDiv = styled.div`
	display: flex;
	align-items: center;
`

const PaddedDivider = styled(Divider)`
	margin: ${(props) => props.theme.spacing(1.5)} 0;
	border-color: #eeeeee;
`

const GreyStyledCard = styled(Card)`
	background-color: #fafafa;
	border-radius: 12px;
	margin-top: 32px;
`

const DiscountStyledCard = styled(
	({isEligible, marginTop, ...props}: {isEligible: boolean; marginTop?: boolean} & CardProps) => <Card {...props} />,
)`
	background-color: ${(props) => (props.isEligible ? "#fafafa" : "#fff2f4")};
	border-radius: 12px;
	margin-top: ${(props) => (props.marginTop ? "32px" : "16px")};
`

const CardContentLessPadding = styled(CardContent)`
	padding: 0 16px;
	padding-bottom: 10px !important;
`

const DiscountCardContent = styled(CardContent)`
	padding: 0px 45px;
	padding-bottom: 10px !important;
`

const DiscountCardContentUl = styled.ul`
	margin: 3px 0px;
`

const DiscountMessageBox = styled(Box)`
	display: flex;
`

const CardHeaderLessPadding = styled(CardHeader)`
	padding: 12px 16px;
`

const GradientHeaderGrid = styled(({theme, padded, ...props}) => <Grid {...props} />).attrs({
	container: true,
	direction: "column",
	alignItems: "flex-start",
	spacing: 6,
})`
	padding-top: ${(props) => props.theme.spacing(5)};
	padding-bottom: ${(props) => props.theme.spacing(5)};
	background: linear-gradient(
		130deg,
		rgba(241, 247, 254, 1) 0%,
		rgba(241, 247, 254, 0.52) 40%,
		rgba(160, 233, 227, 0.32) 100%
	);
	@media (max-width: 599px) {
		margin: -16px;
		margin-bottom: 32px;
	}
	margin-bottom: 32px;

	${(props) =>
		props.padded &&
		`
		padding-left: ${props.theme.spacing(2)};
		padding-right: ${props.theme.spacing(2)};
	`}
`

const GridWithBottomMargin = styled(Grid)`
	margin-bottom: 10px;
`
const GridWithHorizontalPadding = styled(Grid)`
	padding-left: ${(props) => props.theme.spacing(2)};
	padding-right: ${(props) => props.theme.spacing(2)};
`

const GridWithPadding = styled(Grid)`
	padding-left: ${(props) => props.theme.spacing(4)};
	padding-right: ${(props) => props.theme.spacing(4)};

	@media (min-width: 440px) {
		padding-left: ${(props) => props.theme.spacing(10)};
		padding-right: ${(props) => props.theme.spacing(10)};
	}
`

const BulletList = styled(({color, ...p}) => <ul {...p} />)`
	margin-bottom: 10px;
	padding: 0 16px;
	font-size: 14px;
	line-height: 20px;
	color: ${(props) => props.color};
`

export {
	GridWithPadding,
	GridWithBottomMargin,
	GridWithHorizontalPadding,
	GradientHeaderGrid,
	CardHeaderLessPadding,
	DiscountMessageBox,
	DiscountCardContentUl,
	DiscountCardContent,
	CardContentLessPadding,
	DiscountStyledCard,
	GreyStyledCard,
	StyledDiv,
	FailedPaymentTypography,
	CurrencyTypography,
	PaidAmountTypography,
	DiscountCardHeadingTypography,
	CardHeadingTypography,
	StyledTypography,
	ColoredTypographyWithBottomMargin,
	DiscountColoredTypography,
	ColoredTypography,
	PaddedDivider,
	BulletList,
}
