import React, {useEffect, useState} from "react"
import {Routes, Route, useSearchParams, useParams} from "react-router-dom"
import {ErrorPage, NotFoundPage} from "@indebted/components/Error"
import {LoadingPage} from "@indebted/components/Loading"
import {optOutAPI, setRegionFromAWSRegion} from "@indebted/api"
import {track} from "@indebted/analytics"

import {OptOutConfirmed} from "./OptOutConfirmed"
import {OptOutConfirmation} from "./OptOutConfirmation"
import {OptOutAlreadyCompleted} from "./OptOutAlreadyCompleted"
import {OptOutFailurePage} from "./OptOutFailurePage"

function OptOutPage() {
	const {token, channel} = useParams()
	const [Component, setComponent] = useState(<LoadingPage />)

	const [searchParams] = useSearchParams()
	const awsRegion = searchParams.get("region")
	setRegionFromAWSRegion(awsRegion)

	function OptOutPages({I18n}) {
		return (
			<Routes>
				<Route element={<OptOutConfirmation I18n={I18n.ConfirmationPage} />} path="/" />
				<Route element={<OptOutConfirmed />} path="/confirm" />

				<Route element={<NotFoundPage />} path="*" />
			</Routes>
		)
	}

	useEffect(() => {
		function statusComponent(status) {
			return {
				Pending: OptOutPages,
				OptedOut: OptOutAlreadyCompleted,
			}[status]
		}

		optOutAPI
			.find(token, channel)
			.then((result) => {
				track("UnsubscribeConfirmPageViewed", {
					Status: result.Status,
				})
				const StatusComponent = statusComponent(result.Status)

				setComponent(<StatusComponent {...result} />)
			})
			.catch((error) => {
				const errors = {
					404: <OptOutFailurePage I18n={error.i18n} />,
					500: <ErrorPage message={error.Message} error={error} />,
				}

				setComponent(errors[error.Status] || errors[500])
			})
	}, [token, channel])

	return Component
}

export {OptOutPage}
