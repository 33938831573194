import React, {useEffect, useState} from "react"
import {LoadingPage} from "@indebted/components/Loading"
import {ErrorPage} from "@indebted/components/Error"
import {loadStripe, Stripe, StripeElementLocale} from "@stripe/stripe-js"
import {Elements} from "@stripe/react-stripe-js"

const errorTitle = "Failed contacting payment provider"
const errorMessage = (
	<>
		We apologize for the inconvenience.
		<br />
		Please try again.
	</>
)

function withStripe<T>(
	Component: React.ComponentType<T>,
): React.ComponentType<{providerPublishableKey: string; locale: StripeElementLocale} & T> {
	return function WithStripe(props: {providerPublishableKey: string; locale: StripeElementLocale} & T) {
		const [stripe, setStripe] = useState<Stripe>(null)
		const [error, setError] = useState(null)

		useEffect(() => {
			loadStripe(props.providerPublishableKey)
				.then((stripe) => {
					setStripe(stripe)
				})
				.catch((error) => {
					setError(error)
					console.error("Failed loading Stripe client", error)
				})
		}, [props.providerPublishableKey])

		if (error) {
			return <ErrorPage message={errorMessage} title={errorTitle} error={error} />
		}

		if (!stripe) {
			return <LoadingPage />
		}

		return (
			<Elements stripe={stripe} options={{locale: props.locale}}>
				<Component {...props} />
			</Elements>
		)
	}
}

export {withStripe}
