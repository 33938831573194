import React from "react"
import styled from "styled-components"
import {TableContainer, Table, TableBody, TableRow, TableCell, Typography} from "@mui/material"

type InstalmentBreakdownI18n = {
	Header: string
	OutstandingBalance: string
	InstalmentAmount: string
	TotalInstalments: string
}

function InstalmentBreakdown({
	instalmentBreakdownI18n,
	currency,
	outstandingBalance,
	amountInCents,
}: {
	instalmentBreakdownI18n: InstalmentBreakdownI18n
	currency: string
	outstandingBalance: number
	amountInCents: number
}) {
	const formattedOutstandingBalance = (outstandingBalance / 100).toFixed(2)
	const formattedAmount = (amountInCents / 100).toFixed(2)
	const totalInstalments = amountInCents > 0 ? Math.ceil(outstandingBalance / amountInCents) : 0

	return (
		<StyledDiv>
			<Typography variant="subtitle2" align="left" style={{fontSize: "1rem"}}>
				{instalmentBreakdownI18n.Header}
			</Typography>
			<TableContainer>
				<StyledTable>
					<TableBody>
						<TableRow data-testid="instalment-breakdown-outstanding-balance">
							<TableCell>{instalmentBreakdownI18n.OutstandingBalance}</TableCell>
							<TableCell align="right">{`${currency} ${formattedOutstandingBalance}`}</TableCell>
						</TableRow>
						<TableRow data-testid="instalment-breakdown-instalment-amount">
							<TableCell>{instalmentBreakdownI18n.InstalmentAmount}</TableCell>
							<TableCell align="right">{`${currency} ${formattedAmount}`}</TableCell>
						</TableRow>
						<TableRow data-testid="instalment-breakdown-total-instalments">
							<TableCell>{instalmentBreakdownI18n.TotalInstalments}</TableCell>
							<TableCell align="right">{totalInstalments}</TableCell>
						</TableRow>
					</TableBody>
				</StyledTable>
			</TableContainer>
		</StyledDiv>
	)
}

const StyledDiv = styled.div`
	padding: 0 10px;
`

const StyledTable = styled(Table)`
	margin-top: 0.1rem;

	.MuiTableCell-root {
		padding: 0.5rem;
	}

	.MuiTableCell-root:last-child {
		font-weight: bold;
	}

	.MuiTableRow-root:last-child {
		background-color: rgba(189, 191, 242, 0.34);
		border-bottom: 2px solid rgba(2, 4, 82, 0.46);
	}
`

export {InstalmentBreakdown, InstalmentBreakdownI18n}
