import React from "react"
import {Page} from "@indebted/components/Page"
import {Button, Typography} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import {PaymentCompletedImage} from "@indebted/assets"
import {Prop, PropLabel, Props, PropValue} from "@indebted/components/Props"
import styled from "styled-components"
import {useNavigate, useParams} from "react-router-dom"
import {track} from "@indebted/analytics"

function PaymentSucceeded({ClientName, Amount, I18n}) {
	const navigate = useNavigate()
	const {secureCode} = useParams()

	function makeAnotherPaymentButtonClicked() {
		track("PartialPaymentMakeAnotherPaymentButtonClicked", {
			SecureCode: secureCode,
		})
		navigate(`/${secureCode}`)
	}

	return (
		<Page>
			<Grid>
				<Typography align="center" variant="h6">
					{I18n.PaymentSucceeded.Title}
				</Typography>
			</Grid>

			<Grid>
				<Typography align="center">{I18n.PaymentSucceeded.Subtitle}</Typography>
			</Grid>

			<GridImage>
				<PaymentCompletedImage />
			</GridImage>

			<Props>
				<Prop>
					<PropLabel align="right">{I18n.PaymentSucceeded.PaymentAmountLabel}</PropLabel>
					<PropValue align="left">{Amount}</PropValue>
				</Prop>
				<Prop>
					<PropLabel align="right">{I18n.PaymentSucceeded.PaidToLabel}</PropLabel>
					<PropValue align="left" className="clarity-mask">
						{ClientName}
					</PropValue>
				</Prop>
			</Props>
			<Grid>
				<Button onClick={makeAnotherPaymentButtonClicked} style={{marginTop: "35px"}}>
					{I18n.PaymentSucceeded.MakeAnotherPaymentButtonText}
				</Button>
			</Grid>
		</Page>
	)
}

const GridImage = styled(Grid)`
	margin: ${(props) => props.theme.spacing(5)};
`

export {PaymentSucceeded}
