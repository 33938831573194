import React from "react"
import {Route, Routes, useParams} from "react-router-dom"
import {setRegionFromRegionNumber} from "@indebted/api"
import {NotFoundPage} from "@indebted/components/Error"

import {FrequencyPage} from "./FrequencySelection"
import {StartDatePage} from "./StartDateSelection"
import {AmountSelectionPage} from "./AmountSelection"
import {PaymentMethodPage} from "./PaymentMethodSelection"
import {StatusPage} from "./StatusPage"
import {ManagePaymentArrangementPage} from "./ManagePaymentArrangement"

function PaymentArrangementRouter() {
	const {region} = useParams()
	setRegionFromRegionNumber(region)
	return (
		<Routes>
			<Route path=":id">
				<Route element={<FrequencyPage />} path="frequency" />
				<Route element={<StartDatePage />} path="start-date" />
				<Route element={<AmountSelectionPage />} path="amount" />
				<Route element={<PaymentMethodPage />} path="payment-method" />
				<Route element={<StatusPage />} path="status" />
				<Route element={<ManagePaymentArrangementPage />} path="manage" />
				<Route element={<NotFoundPage />} index />
			</Route>
			<Route element={<NotFoundPage />} path="*" />
		</Routes>
	)
}

export {PaymentArrangementRouter}
