import React from "react"
import {ErrorPage} from "@indebted/components/Error"

import {OutstandingBalance} from "./OutstandingBalance"
import {LowOutstandingBalance} from "./LowOutstandingBalance"
import {PaymentBeingProcessing} from "./PaymentBeingProcessed"
import {SettledAllAccounts} from "./SettledAllAccounts"
import {InPaymentPlan} from "./InPaymentPlan"
import {Dispute} from "./Dispute"
import {PaymentsDisabled} from "./PaymentsDisabled"
import {Recalled} from "./Recalled"
import {CaseEscalated} from "./CaseEscalated"
import {NotificationOfAssignment} from "./NotificationOfAssignment"
import {BankruptcyScrubDetected} from "./BankruptcyScrubDetected"
import {ScrubDetected} from "./ScrubDetected"
import {AccountsClosedDueToSOL} from "./AccountsClosedDueToSOL"
import {PendingScrub} from "./PendingScrub"

function CustomerStatuses({status, payload, i18n}) {
	const statusComponentMap = {
		OutstandingBalance: OutstandingBalance,
		LowOutstandingBalance: LowOutstandingBalance,
		PaymentBeingProcessed: PaymentBeingProcessing,
		SettledAllAccounts: SettledAllAccounts,
		InPaymentPlan: InPaymentPlan,
		Dispute: Dispute,
		PaymentsDisabled: PaymentsDisabled,
		Recalled: Recalled,
		CaseEscalated: CaseEscalated,
		BankruptcyScrubDetected: BankruptcyScrubDetected,
		NotificationOfAssignment: NotificationOfAssignment,
		DeceasedScrubDetected: ScrubDetected,
		ScrubDetected: ScrubDetected,
		AccountsClosedDueToSOL: AccountsClosedDueToSOL,
		PendingScrub: PendingScrub,
	}

	const StatusComponent = statusComponentMap[status] || (() => <ErrorPage message="Unknown customer status" />)

	return <StatusComponent i18n={i18n} {...payload} />
}

export {CustomerStatuses}
