import React, {useEffect, useState} from "react"
import {paymentArrangementAPI} from "@indebted/api"
import {useParams} from "react-router-dom"
import {LoadingPage} from "@indebted/components/Loading"
import {ErrorPage} from "@indebted/components/Error"

import {ManagePageType} from "../types"

import {ManagePaymentArrangement} from "./ManagePaymentArrangement"

const ManagePaymentArrangementPage = () => {
	const {id: paymentArrangementID} = useParams()
	const [Component, setComponent] = useState(<LoadingPage />)
	useEffect(() => {
		paymentArrangementAPI
			.getManagePage({paymentArrangementID})
			.then((result: ManagePageType) => {
				setComponent(<ManagePaymentArrangement {...result} />)
			})
			.catch((error) => {
				setComponent(<ErrorPage message={error.Message} error={error} />)
			})
	}, [paymentArrangementID])

	return Component
}

export {ManagePaymentArrangementPage}
