import React, {useEffect, useState} from "react"
import {LoadingPage} from "@indebted/components/Loading"
import {ErrorPage} from "@indebted/components/Error"
import {useNavigate, useParams} from "react-router-dom"
import {useNotification} from "@indebted/components/Notification"
import {paymentArrangementAPI} from "@indebted/api"
import {PaymentFormPageResponse} from "@indebted/api/paymentArrangementAPI"
import {StripeElementLocale} from "@stripe/stripe-js"

import {nextPage} from "../Navigator"

import {DebitCardForm} from "./DebitCardForm"

type DebitCardFormProps = React.ComponentProps<typeof DebitCardForm>
type OnSubmitType = DebitCardFormProps["onSubmit"]

function PaymentMethodPage() {
	const {id} = useParams()
	const [Component, setComponent] = useState(<LoadingPage />)
	useEffect(() => {
		paymentArrangementAPI
			.getPaymentFormPage({paymentArrangementID: id})
			.then((result) => {
				setComponent(<Form {...result} />)
			})
			.catch((error) => {
				setComponent(<ErrorPage message={error.Message} error={error} />)
			})
	}, [id])

	return Component
}

function Form({
	I18n,
	Locale,
	ProviderData,
	HidePostalCode,
	AcceptedCards,
	MorePaymentMethodsAvailable,
	PaymentArrangementID,
	PaymentMethod,
}: PaymentFormPageResponse) {
	const {notification} = useNotification()
	const navigate = useNavigate()

	const onSubmit: OnSubmitType = async ({stripeAPI}) => {
		try {
			const response = await stripeAPI.confirmSetup(ProviderData.ClientSecret)
			const receiveSetupSubmittedResponse = await paymentArrangementAPI.receiveSetupSubmitted({
				PaymentArrangementID: PaymentArrangementID,
				ProviderName: "Stripe",
				ProviderRawEvent: response.ProviderRawEvent,
				ProviderSetupID: response.ProviderSetupID,
				SubmittedAt: new Date().toISOString(),
			})
			navigate(nextPage(receiveSetupSubmittedResponse.Next, PaymentArrangementID))
		} catch (error) {
			notification.error(error.message, 5000)
			throw error
		}
	}

	const stripeLocale = localeToStripeLocale(Locale)
	const map = {
		DebitCard: (
			<DebitCardForm
				i18n={I18n}
				locale={stripeLocale}
				providerPublishableKey={ProviderData.PublishableKey}
				hidePostalCode={HidePostalCode}
				acceptedCards={AcceptedCards}
				morePaymentMethodsAvailable={MorePaymentMethodsAvailable}
				onSubmit={onSubmit}
			/>
		),
	}
	return map[PaymentMethod] || <ErrorPage message={I18n.PaymentNotSupportedError} />
}

function localeToStripeLocale(Locale: string): StripeElementLocale {
	switch (Locale) {
		case "en-US":
			return "en"
		case "en-AE":
		case "en-GB":
			return "en-GB"
		case "en-CA":
			return "en-CA"
		case "en-AU":
			return "en-AU"
		case "en-NZ":
			return "en-NZ"
		case "es-MX":
		case "es-US":
			return "es"
		case "fr-CA":
			return "fr-CA"
		default:
			console.warn("Stripe doesn't support locale", Locale)
			return Locale as StripeElementLocale
	}
}

export {PaymentMethodPage}
