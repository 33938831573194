import React, {useEffect, useState} from "react"
import {paymentArrangementAPI} from "@indebted/api"
import {useNavigate, useParams} from "react-router-dom"
import {Page} from "@indebted/components/Page"
import Grid from "@mui/material/Unstable_Grid2"
import {Box, Button, Typography} from "@mui/material"
import styled from "styled-components"
import {LoadingPage} from "@indebted/components/Loading"
import {ErrorPage} from "@indebted/components/Error"
import {CalendarCheckIcon} from "@indebted/assets"
import {Calendar} from "@indebted/components/Calendar"
import {addDays} from "date-fns"

import {CustomStartDate, StartDateI18N, StartDatePageType, StartToday} from "../types"
import {nextPage} from "../Navigator"

const CalendarCustomStartDate = ({
	locale,
	customStartDate,
	configureStartDate,
}: {
	locale: string
	customStartDate: CustomStartDate
	configureStartDate: (startDate: Date) => Promise<void>
}) => {
	const minDate = new Date()
	const [selectedDate, setSelectedDate] = useState(minDate)
	const maxDate = addDays(new Date(), customStartDate.MaximumDaysUntilStart)

	return (
		<Page padded>
			<Grid container direction="column" alignItems="stretch" justifyContent="center" spacing={5}>
				<Grid container padding={0}>
					<Grid>
						<Typography variant="h6">{customStartDate.I18n.Header}</Typography>
					</Grid>
					<Grid>
						<Typography>{customStartDate.I18n.Title}</Typography>
					</Grid>
				</Grid>

				<Box style={{overflow: "hidden"}}>
					<Calendar
						date={selectedDate}
						onChange={setSelectedDate}
						disablePast={true}
						minDate={minDate}
						maxDate={maxDate}
						locale={locale}
					/>
				</Box>
				<Grid container>
					<Button fullWidth variant="outlined" onClick={() => configureStartDate(selectedDate)}>
						{customStartDate.Continue.Display}
					</Button>
				</Grid>
			</Grid>
		</Page>
	)
}
const StartDateForm = ({
	i18n,
	startToday,
	customStartDate,
	selectCustomStartDate,
	configureStartDate,
}: {
	i18n: StartDateI18N
	startToday: StartToday
	customStartDate: CustomStartDate
	selectCustomStartDate: () => void
	configureStartDate: (startDate: Date) => Promise<void>
}) => {
	return (
		<Page padded>
			<Grid container direction="column" alignItems="stretch" justifyContent="space-between">
				<Grid padding={0}>
					<Grid container direction="column" alignItems="stretch" spacing={5}>
						<Grid>
							<Typography variant="h6">{i18n.Header}</Typography>
						</Grid>
						<Grid>
							<Typography>{i18n.Title}</Typography>
						</Grid>
					</Grid>

					<ButtonsGrid container direction="column" alignItems="stretch" spacing={5}>
						<Grid>
							<Button fullWidth variant="outlined" onClick={() => configureStartDate(new Date())}>
								{startToday.Display}
							</Button>
						</Grid>
						<Grid>
							<Button
								fullWidth
								variant="outlined"
								startIcon={<CalendarCheckIcon />}
								onClick={selectCustomStartDate}
							>
								{customStartDate.Display}
							</Button>
						</Grid>
					</ButtonsGrid>
				</Grid>
			</Grid>
		</Page>
	)
}

const ButtonsGrid = styled(Grid)`
	margin-top: 20px;
`

const StartDatePage = () => {
	const {id: paymentArrangementID} = useParams()
	const [Component, setComponent] = useState(<LoadingPage />)
	const navigate = useNavigate()

	const configureStartDate = (startDate: Date) => {
		return paymentArrangementAPI
			.configureStartDate({paymentArrangementID, startDate: startDate.toISOString()})
			.then((result) => {
				navigate(nextPage(result.Next, paymentArrangementID))
			})
			.catch((error) => {
				setComponent(<ErrorPage message={error.Message} error={error} />)
			})
	}

	const onSelectCustomStartDate = (result: StartDatePageType) => {
		setComponent(
			<CalendarCustomStartDate
				locale={result.Locale}
				customStartDate={result.CustomStartDate}
				configureStartDate={configureStartDate}
			/>,
		)
	}

	useEffect(() => {
		paymentArrangementAPI
			.getConfigureStartDatePage({paymentArrangementID})
			.then((result: StartDatePageType) => {
				setComponent(
					<StartDateForm
						i18n={result.I18n}
						startToday={result.StartToday}
						customStartDate={result.CustomStartDate}
						selectCustomStartDate={() => onSelectCustomStartDate(result)}
						configureStartDate={configureStartDate}
					/>,
				)
			})
			.catch((error) => {
				setComponent(<ErrorPage message={error.Message} error={error} />)
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paymentArrangementID])

	return Component
}

export {StartDatePage, StartDateForm}
