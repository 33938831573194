import React, {useEffect, useState} from "react"
import {paymentArrangementAPI, setRegionFromRegionNumber} from "@indebted/api"
import {LoadingPage} from "@indebted/components/Loading"
import {ErrorPage} from "@indebted/components/Error"
import {useLocation, useNavigate, useParams} from "react-router-dom"

import {nextPage} from "../Navigator"

function StatusPage() {
	const {id: paymentArrangementID, region} = useParams()
	setRegionFromRegionNumber(region)
	const location = useLocation()
	const i18n = location?.state?.I18n
	const [Component, setComponent] = useState(<LoadingPage I18n={i18n} />)
	const navigate = useNavigate()

	useEffect(() => {
		paymentArrangementAPI
			.pollConfirmation(paymentArrangementID)
			.then((result) => {
				navigate(nextPage(result.Next, paymentArrangementID))
			})
			.catch((error) => {
				setComponent(<ErrorPage message={error.Message} error={error} />)
			})
	}, [navigate, paymentArrangementID, region])

	return <div data-testid="payment-arrangement-status-page">{Component}</div>
}

export {StatusPage}
