import React from "react"
import styled from "styled-components"
import {NotEligible, Eligible} from "@indebted/assets"
import {Typography, Box} from "@mui/material"

import {TermsOfDiscountOffer} from "./TermsOfDiscountOffer"

function DiscountMessage({discountMessage}) {
	if (!discountMessage) return <></>
	return (
		<DiscountMessageBox bordercolor={discountMessage.Eligible ? "#57D9A3" : "#FF9898"}>
			<Box>{discountMessage.Eligible ? <Eligible /> : <NotEligible />}</Box>
			<Box>
				<Typography variant="subtitle1">{discountMessage.Message}</Typography>
				<Typography variant="body2">{discountMessage.Description}</Typography>
				{Array.isArray(discountMessage.Bulletpoints) && (
					<ul>
						{discountMessage.Bulletpoints.map((point) => (
							<li key={point}>{point}</li>
						))}
						<TermsOfDiscountOffer linkLabel={discountMessage.TermsAndConditionsLabel} />
					</ul>
				)}
				<Typography variant="body2">{discountMessage.Footnote}</Typography>
			</Box>
		</DiscountMessageBox>
	)
}

const DiscountMessageBox = styled(Box)<{bordercolor: string}>`
	display: flex;
	gap: 10px;
	background-color: #f5f5f8;
	padding: ${(props) => props.theme.spacing(4)};
	border-top: 2px solid ${(props) => props.bordercolor};

	h6 {
		line-height: 1.5;
		margin-bottom: 10px;
	}

	ul {
		list-style: none;
		padding-left: 22px;
	}

	ul li:nth-child(odd) {
		margin-bottom: 8px;
	}

	ul li:before {
		padding: 8px;
		margin-left: -30px;
		content: "✓";
	}
`

export {DiscountMessage}
