import {PaymentMethodResult, Stripe} from "@stripe/stripe-js"
import {StripeCardElement, StripeCardNumberElement} from "@stripe/stripe-js/dist/stripe-js/elements/index"

function Api({
	form,
	debitCardElement,
	stripeSDK,
	fundingTypeErrorMessage,
}: {
	form: {
		Name: string
		Email: string
	}
	debitCardElement: StripeCardElement | StripeCardNumberElement | {token: string}
	stripeSDK: Stripe
	fundingTypeErrorMessage: string
}) {
	async function confirmSetup(clientSecret: string) {
		let result = await stripeSDK.createPaymentMethod({
			type: "card",
			card: debitCardElement,
			billing_details: {
				name: form.Name,
				email: form.Email,
			},
		})
		if (result.error) throw result.error
		result = validateFundingType(result)

		const setupIntentResult = await stripeSDK.confirmCardSetup(clientSecret, {
			payment_method: result.paymentMethod.id,
		})
		if (setupIntentResult.error) throw setupIntentResult.error

		return {ProviderRawEvent: [result, setupIntentResult], ProviderSetupID: setupIntentResult.setupIntent.id}
	}

	async function createPaymentMethod() {
		const result = await stripeSDK.createPaymentMethod({
			type: "card",
			card: debitCardElement,
			billing_details: {
				name: form.Name,
				email: form.Email,
			},
		})
		if (result.error) throw result.error
		return validateFundingType(result)
	}

	function createPaymentRequest(
		PaymentAmount: number,
		PaymentCurrency: string,
		PaymentCountry: string,
		Label: string,
	) {
		return stripeSDK.paymentRequest({
			country: PaymentCountry,
			currency: PaymentCurrency.toLowerCase(),
			total: {
				label: Label,
				amount: PaymentAmount,
			},
			requestPayerName: true,
			requestPayerEmail: true,
		})
	}

	async function confirmPayment(clientSecret: string) {
		let result = await stripeSDK.createPaymentMethod({
			type: "card",
			card: debitCardElement,
			billing_details: {
				name: form.Name,
				email: form.Email,
			},
		})
		if (result.error) throw result.error
		result = validateFundingType(result)
		const confirmResponseResult = await stripeSDK.confirmCardPayment(clientSecret, {
			payment_method: result.paymentMethod.id,
		})
		if (confirmResponseResult.error) throw confirmResponseResult.error
	}

	function validateFundingType(result: PaymentMethodResult) {
		if (!["debit", "prepaid"].includes(result.paymentMethod.card.funding)) {
			throw {message: fundingTypeErrorMessage}
		}

		return result
	}

	return {createPaymentMethod, confirmSetup, confirmPayment, createPaymentRequest}
}

type StripeApiType = ReturnType<typeof Api>

export {Api, StripeApiType}
