import React, {useEffect, useState} from "react"
import {LoadingPage} from "@indebted/components/Loading"
import {affordabilityAssessmentAPI} from "@indebted/api"
import {ErrorPage} from "@indebted/components/Error"
import {useNavigate, useParams} from "react-router-dom"

function Initiate() {
	const {secureCode, paymentOptionType} = useParams()
	const navigate = useNavigate()
	const [Component, setComponent] = useState(<LoadingPage />)

	useEffect(() => {
		affordabilityAssessmentAPI
			.create({paymentOptionType, secureCode})
			.then((result) => {
				navigate(`/${secureCode}/affordability-assessment/${result.ID}`, {replace: true})
			})
			.catch((error) => {
				setComponent(
					<ErrorPage
						title="Failed to fetch affordability assessment short form"
						message={error.Message}
						error={error}
					/>,
				)
			})
	}, [secureCode, paymentOptionType, navigate])

	return Component
}

export {Initiate}
