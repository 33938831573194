import {getRegionNumber} from "@indebted/api/regionManager"

function nextPage(serverNextPage: string | undefined, paymentArrangementID: string): string {
	if (serverNextPage === undefined || serverNextPage === "") {
		return `/${getRegionNumber()}/payment-arrangement/${paymentArrangementID}/frequency`
	}
	return `/${getRegionNumber()}/payment-arrangement/${paymentArrangementID}/${serverNextPage.toLowerCase()}`
}

export {nextPage}
