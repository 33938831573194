import {OXXOForm} from "./OXXO"
import {Form as AuBankAccountForm, Disclaimer as StripeAuBankAccountDisclaimer} from "./AuDirectDebit"
import {Form as UKBankAccountForm} from "./UKDirectDebit"
import {DebitCardForm} from "./DebitCard"
import {Form as USBankAccountForm} from "./USDirectDebit"
import {withStripe} from "./withStripe"
import {DebitCardWithEFTAForm} from "./DebitCardWithEFTA"
import {DebitCardWithCPAForm} from "./DebitCardWithCPA"

const StripeAuBankAccountForm = withStripe(AuBankAccountForm, ["au_bank_account_beta_2"])
const StripeUKBankAccountForm = withStripe(UKBankAccountForm)
const StripeDebitCardForm = withStripe(DebitCardForm)
const StripeDebitCardWithEFTAForm = withStripe(DebitCardWithEFTAForm)
const StripeUSBankAccountForm = withStripe(USBankAccountForm, ["us_bank_account_beta_2"])
const StripeDebitCardWithCPAForm = withStripe(DebitCardWithCPAForm)
const StripeOXXOForm = withStripe(OXXOForm)

export {
	StripeDebitCardForm,
	StripeDebitCardWithEFTAForm,
	StripeAuBankAccountForm,
	StripeAuBankAccountDisclaimer,
	StripeUKBankAccountForm,
	StripeUSBankAccountForm,
	StripeDebitCardWithCPAForm,
	StripeOXXOForm,
}
