import React, {useEffect, useState} from "react"
import styled from "styled-components"
import {Page} from "@indebted/components/Page"
import {AmountInput} from "@indebted/components/AmountInput"
import {Typography, Button, Collapse} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import {track} from "@indebted/analytics"
import {LoadingPage} from "@indebted/components/Loading"
import {paymentPlanAPI} from "@indebted/api"
import {ErrorPage} from "@indebted/components/Error"
import {TextToHTML} from "@indebted/components/TextToHTML"
import {useSearchParams, useParams, useNavigate} from "react-router-dom"

import {InstalmentBreakdown} from "./InstalmentBreakdown"

function AmountSelectionPage({frequencyOptions}) {
	const {secureCode} = useParams()
	const navigate = useNavigate()
	const [Component, setComponent] = useState(<LoadingPage />)
	useEffect(() => {
		paymentPlanAPI
			.getAmountPage({secureCode})
			.then((result) => {
				setComponent(
					<AmountSelection
						secureCode={secureCode}
						options={result}
						frequencyOptions={frequencyOptions}
						navigate={navigate}
					/>,
				)
			})
			.catch((error) => {
				setComponent(<ErrorPage message={error.Message} error={error} />)
			})
	}, [secureCode, frequencyOptions, navigate])

	return Component
}

function AmountSelection({secureCode, frequencyOptions, options, navigate}) {
	const [amount, setAmount] = useState()

	const [minimumAmountError, setMinimumAmountError] = useState(false)
	const [showInstalmentBreakdown, setShowInstalmentBreakdown] = useState(false)

	const [searchParams] = useSearchParams()
	const frequencyParam = searchParams.get("frequency")
	const minimumAmount = options.MinimumInstalmentAmount[frequencyParam] / 100
	const minimumAmountErrorMessage = (
		<TextToHTML
			style={{color: "#D7153A", fontSize: "0.85rem"}}
			component="span"
			text={options.I18n.AmountSelection.MinimumAmountErrorMessages[frequencyParam]}
		/>
	)
	const frequency = frequencyOptions.find(({ID}) => ID === frequencyParam).DisplayText

	useEffect(() => {
		const delayInputTimeoutId = setTimeout(() => {
			const error = amount && amount < minimumAmount
			setMinimumAmountError(error)
			setShowInstalmentBreakdown(!!(options.ShowInstalmentBreakdownTable && amount && !error))
		}, 750)
		return () => clearTimeout(delayInputTimeoutId)
	}, [amount, minimumAmount, options.ShowInstalmentBreakdownTable])

	useEffect(() => {
		track("PPAmountPageViewed", {
			SecureCode: secureCode,
		})
	}, [secureCode])

	const handleAmountInput = ({target: {value}}) => {
		setAmount(value)
	}

	const buttonDisabled = () => {
		return !amount || amount < minimumAmount
	}

	return (
		<Page padded>
			<Grid container direction="column" alignItems="stretch" padding={0} style={{width: "100%"}}>
				<Grid style={{paddingLeft: 0}}>
					<Typography variant="h6">{options.I18n.AmountSelection.Header}</Typography>
				</Grid>
				<Grid style={{paddingLeft: 0}}>
					<Typography>{options.I18n.AmountSelection.Title}</Typography>
				</Grid>

				<AmountGrid container direction="column" alignItems="stretch" spacing={5} padding={0}>
					<Grid>
						<AmountInput
							label={options.I18n.AmountSelection.AmountInputLabel}
							error={minimumAmountError}
							value={amount}
							currency={options.Currency}
							helperText={minimumAmountError && minimumAmountErrorMessage}
							onChange={handleAmountInput}
							required
							autoFocus
						/>
					</Grid>
					<Collapse in={showInstalmentBreakdown} timeout="auto">
						<Grid>
							<InstalmentBreakdown
								instalmentBreakdownI18n={options.I18n.InstalmentBreakdown}
								currency={options.Currency}
								outstandingBalance={options.OutstandingBalance}
								amount={amount || 0}
								frequency={frequency}
							/>
						</Grid>
					</Collapse>
					<Grid>
						<Button
							fullWidth
							disabled={buttonDisabled()}
							onClick={() => {
								track("PPAmountButtonClicked", {
									SecureCode: secureCode,
								})
								navigate(
									`/${secureCode}/payment-plan/${options.DefaultPaymentMethod}?${searchParams}&amount=${amount}`,
								)
							}}
						>
							{options.I18n.AmountSelection.AmountButtonLabel}
						</Button>
					</Grid>
				</AmountGrid>
			</Grid>
		</Page>
	)
}

const AmountGrid = styled(Grid)`
	margin-top: 10px;
	text-align: center;
`

export {AmountSelectionPage}
