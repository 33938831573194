import React, {useState, FormEvent} from "react"
import {CardElement as StripeCardElement, useElements, useStripe} from "@stripe/react-stripe-js"
import {Button, FormControl, FormHelperText, InputLabel, Link, OutlinedInput, Typography} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import {useNotification} from "@indebted/components/Notification"
import {AcceptedCards, CardType} from "@indebted/components/AcceptedCards"
import {BankAccountIcon} from "@indebted/assets"
import {DebitCardFormI18nType} from "@indebted/api/paymentArrangementAPI"

import {Element} from "./Element"
import {Api, StripeApiType} from "./api"

type DebitCardFormProps = {
	onSubmit: ({stripeAPI}: {stripeAPI: StripeApiType}) => Promise<any>
	i18n: DebitCardFormI18nType
	hidePostalCode?: boolean
	acceptedCards: CardType[]
	morePaymentMethodsAvailable: boolean
	paymentMethodSelectionLink: string
}

function DebitCardForm({
	onSubmit,
	i18n,
	hidePostalCode,
	acceptedCards,
	morePaymentMethodsAvailable,
	paymentMethodSelectionLink,
}: DebitCardFormProps) {
	const stripe = useStripe()
	const {notification} = useNotification()
	const [submitting, setSubmitting] = useState(false)
	const [form, setForm] = useState({Email: "", Name: ""})
	const elements = useElements()

	const handleInput =
		(name: string) =>
		({target: {value}}) => {
			setForm({...form, [name]: value})
		}

	const debitCardElement = elements.getElement(StripeCardElement)

	const stripeAPI = Api({
		stripeSDK: stripe,
		form,
		debitCardElement,
		fundingTypeErrorMessage: i18n.FormErrorsFundingType,
	})

	// eslint-disable-next-line complexity
	const submit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault()

		// See https://stripe.com/docs/stripe-js/react#useelements-hook
		if (!stripe || !elements) {
			return
		}

		const emailValidationRegex =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

		if (form.Name.trim() == "" || !form.Email.match(emailValidationRegex)) {
			notification.error(i18n.FormErrorsEmailNameValidation, 5000)
			return
		}

		setSubmitting(true)

		onSubmit({
			stripeAPI,
		}).catch(() => {
			setSubmitting(false)
		})
	}

	return (
		<form onSubmit={submit}>
			<Grid container direction="column" alignItems="stretch" spacing={5}>
				<Grid container>
					<Grid container direction="column" alignItems="stretch" spacing={5} style={{flex: 1}}>
						<Grid>
							<FormControl variant="outlined" fullWidth={true}>
								<InputLabel htmlFor="fullname">{i18n.FormFullNameLabel}</InputLabel>
								<OutlinedInput
									id="fullname"
									type="text"
									label={i18n.FormFullNameLabel}
									value={form.Name}
									onChange={handleInput("Name")}
									required
									autoFocus
								/>
							</FormControl>
						</Grid>
						<Grid>
							<FormControl variant="outlined" fullWidth={true}>
								<InputLabel htmlFor="email">{i18n.FormEmailLabel}</InputLabel>
								<OutlinedInput
									id="email"
									label={i18n.FormEmailLabel}
									type="email"
									value={form.Email}
									onChange={handleInput("Email")}
									required
								/>
								<FormHelperText>{i18n.FormEmailHelp}</FormHelperText>
							</FormControl>
						</Grid>
						<Grid>
							<Element hidePostalCode={hidePostalCode} />
							<AcceptedCards list={acceptedCards} />
						</Grid>
					</Grid>
				</Grid>
				<Grid>
					<Typography variant="caption" color="textSecondary" component="p" align="center">
						{i18n.FormTermsAndConditionsText}{" "}
						<Link href={i18n.FormTermsAndConditionsLink} target="_blank" rel="noopener noreferrer">
							{i18n.FormTermsAndConditionsLinkText}
						</Link>
					</Typography>
				</Grid>
				<Grid>
					<Button fullWidth type="submit" disabled={!stripe || submitting}>
						{i18n.FormButtonLabel}
					</Button>
				</Grid>
				{morePaymentMethodsAvailable && (
					<Grid style={{paddingTop: "3px", paddingRight: "5px", display: "flex", alignItems: "flex-start"}}>
						<BankAccountIcon />
						<Typography style={{marginLeft: "5px"}}>
							{i18n.FormPaymentMethodSelectionText}
							<Link href={paymentMethodSelectionLink} rel="noopener noreferrer">
								{i18n.FormPaymentMethodSelectionLinkText}
							</Link>
						</Typography>
					</Grid>
				)}
			</Grid>
		</form>
	)
}

export {DebitCardForm}
