import {getRegionAWSName} from "@indebted/api"
import {Page} from "@indebted/components/Page"
import {Button, Typography} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import {track} from "@indebted/analytics"
import {useNavigate, useParams} from "react-router-dom"
import React from "react"

function OptOutConfirmation({I18n}) {
	const {channel} = useParams()
	const navigate = useNavigate()
	return (
		<Page>
			<Grid>
				<Typography align="center" variant="h6">
					{I18n.Title}
				</Typography>
			</Grid>

			<Grid>
				<Button
					fullWidth
					disableElevation
					onClick={() => {
						track("OptOutButtonClicked")
						navigate(`${channel}/confirm?region=${getRegionAWSName()}`)
					}}
				>
					{I18n.ButtonLabel}
				</Button>
			</Grid>
		</Page>
	)
}

export {OptOutConfirmation}
