import React from "react"
import styled from "styled-components"
import Grid from "@mui/material/Unstable_Grid2"
import {GridProps} from "@mui/material"

const Page = styled(({padded, ...props}: {padded?: boolean} & GridProps) => <Grid {...props} />).attrs({
	container: true,
	direction: "column",
	alignItems: "center",
	spacing: 5,
})`
	flex 1;
	padding: 0;
	padding-top: ${(props) => props.theme.spacing(5)};
	padding-bottom: ${(props) => props.theme.spacing(5)};

	${(props) =>
		props.padded &&
		`
		padding-left: ${props.theme.spacing(5)};
		padding-right: ${props.theme.spacing(5)};
	`}
`

export {Page}
