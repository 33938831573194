import React, {useEffect, useState} from "react"
import {partialPaymentAPI} from "@indebted/api"
import {LoadingPage} from "@indebted/components/Loading"
import {ErrorPage} from "@indebted/components/Error"
import {PaymentConfirmationTimeout} from "@indebted/components/PaymentConfirmationTimeout"
import {track} from "@indebted/analytics"
import {useNavigate, useParams, useLocation} from "react-router-dom"

import {PaymentSucceeded} from "./PaymentSucceeded"
import {UKDirectDebitProcessing} from "./UKDirectDebitProcessing"
import {PaymentBeingProcessing} from "./PaymentBeingProcessing"
import {PaymentFailed} from "./PaymentFailed"

function PartialPaymentStatusPage() {
	const {paymentID, secureCode} = useParams()
	const location = useLocation()
	const i18n = location?.state?.I18n
	const paymentMethod = location?.state?.PaymentMethod
	const [Component, setComponent] = useState(<LoadingPage I18n={i18n} />)
	const navigate = useNavigate()

	useEffect(() => {
		if (!paymentMethod) {
			navigate(`/${secureCode}`)
		}

		if (paymentMethod === "UKDirectDebit") {
			setComponent(<UKDirectDebitProcessing I18n={i18n} />)

			return
		}

		const confirmationTimeout = setTimeout(() => {
			if (i18n) {
				setComponent(<PaymentConfirmationTimeout I18n={i18n} />)
			} else {
				navigate(`/${secureCode}`)
			}
		}, 60000)

		partialPaymentAPI
			.pollConfirmation(paymentID)
			.then((result) => {
				track("PartialPaymentConfirmPageViewed", {
					Status: result.Status,
					SecureCode: secureCode,
				})
				const StatusComponent = paymentStatusComponent(result.Status)
				setComponent(<StatusComponent {...result} />)
				clearTimeout(confirmationTimeout)
			})
			.catch((error) => {
				setComponent(<ErrorPage message={error.Message} error={error} />)
				clearTimeout(confirmationTimeout)
			})
	}, [paymentID, secureCode, i18n, paymentMethod, navigate])

	return Component
}

function paymentStatusComponent(status) {
	const map = {
		Initiated: LoadingPage,
		Submitted: PaymentBeingProcessing,
		Succeeded: PaymentSucceeded,
		Failed: PaymentFailed,
	}

	return map[status] || (() => <ErrorPage message="Unknown payment status" />)
}

export {PartialPaymentStatusPage}
