import React, {useEffect, useState} from "react"
import {ErrorPage} from "@indebted/components/Error"
import {LoadingPage} from "@indebted/components/Loading"
import {transactionalEmailOptOutAPI, setRegionFromAWSRegion} from "@indebted/api"
import {track} from "@indebted/analytics"
import {useSearchParams, useParams} from "react-router-dom"

import {OptOutConfirmed} from "./OptOutConfirmed"

function TransactionalEmailOptOutPage() {
	const {token} = useParams()
	const [Component, setComponent] = useState(<LoadingPage />)

	const [queryParams] = useSearchParams()
	const awsRegion = queryParams.get("region")
	setRegionFromAWSRegion(awsRegion)

	const emailAddress = queryParams.get("emailAddress")
	const emailSentAt = queryParams.get("emailSentAt")
	const templateTypeName = queryParams.get("templateTypeName")

	useEffect(() => {
		transactionalEmailOptOutAPI
			.transactionalEmailOptOut({token, emailAddress, emailSentAt, templateTypeName})
			.then((result: any) => {
				track("TransactionalEmailOptOutSuccessful", {
					TemplateTypeName: templateTypeName,
					EmailSentAt: emailSentAt,
				})

				setComponent(<OptOutConfirmed {...result} />)
			})
			.catch((error) => {
				setComponent(<ErrorPage message={error.Message} awsRegion={awsRegion} error={error} />)
			})
	}, [token, emailAddress, emailSentAt, templateTypeName, awsRegion])

	return Component
}

export {TransactionalEmailOptOutPage}
