import React from "react"
import styled from "styled-components"
import {Snackbar, SnackbarContent} from "@mui/material"
import {colors} from "@indebted/theme"

const Ctx = React.createContext(null)
const useNotification = () => React.useContext(Ctx)

function NotificationProvider({children}) {
	const defaultState = {
		open: false,
		duration: 2500,
		severity: "",
		message: "",
	}

	const [state, setState] = React.useState(defaultState)

	const notification = {
		success: (message, duration = defaultState.duration) => {
			setState({open: true, duration, severity: "success", message})
		},
		error: (message, duration = defaultState.duration) => {
			setState({open: true, duration, severity: "error", message})
		},
	}

	const handleClose = () => {
		setState(defaultState)
	}

	const origin = {
		vertical: "top",
		horizontal: "center",
	} as const

	return (
		<Ctx.Provider value={{notification}}>
			{children}
			<Snackbar anchorOrigin={origin} open={state.open} autoHideDuration={state.duration} onClose={handleClose}>
				<StyledSnackbarContent message={state.message} severity={state.severity} />
			</Snackbar>
		</Ctx.Provider>
	)
}

const colorMap = {
	success: colors.onlineGreen,
	error: colors.errorRed,
}

const StyledSnackbarContent = styled(SnackbarContent)<{severity: string}>`
	background: ${({severity}) => colorMap[severity]};
`

export {NotificationProvider, useNotification}
