import React from "react"
import Grid from "@mui/material/Unstable_Grid2"
import {colors} from "@indebted/theme"
import {MastercardLogo, VisaLogo} from "@indebted/assets"

import {ManagePageI18n} from "../types"

import {
	CardContentLessPadding,
	CardHeaderLessPadding,
	CardHeadingTypography,
	ColoredTypography,
	GreyStyledCard,
	GridWithBottomMargin,
	PaddedDivider,
	StyledDiv,
} from "./styles"

const PaymentArrangementDetailsCard = ({
	I18n,
	InstalmentAmount,
	Frequency,
	EndDate,
	TotalPaymentAmount,
	PaymentMethod,
	PaymentMethodDetails,
	NextDueDate,
}: {
	I18n: ManagePageI18n
	InstalmentAmount: number
	Frequency: string
	EndDate: string
	TotalPaymentAmount: number
	PaymentMethod: string
	PaymentMethodDetails?: Record<string, string>
	NextDueDate: string
}) => {
	return (
		<GreyStyledCard variant="outlined">
			<CardHeaderLessPadding
				title={
					<Grid container direction="row" justifyContent="space-between">
						<CardHeadingTypography color={colors.stratos}>
							{I18n.PaymentArrangementDetailsHeading}
						</CardHeadingTypography>
					</Grid>
				}
			/>
			<CardContentLessPadding>
				<Grid container direction="row" justifyContent="space-between">
					<StyledDiv>
						<ColoredTypography variant="body2" color={colors.stratosLight2}>
							{I18n.PaymentArrangementLabel}
						</ColoredTypography>
					</StyledDiv>
					<StyledDiv>
						<ColoredTypography variant="body2" color={colors.stratosLight2}>
							{InstalmentAmount} {Frequency}
						</ColoredTypography>
					</StyledDiv>
				</Grid>
				<PaddedDivider />
				<Grid container direction="row" justifyContent="space-between">
					<StyledDiv>
						<ColoredTypography variant="body2" color={colors.stratosLight2}>
							{I18n.NextInstalmentDueDateLabel}
						</ColoredTypography>
					</StyledDiv>
					<StyledDiv>
						<ColoredTypography variant="body2" color={colors.stratosLight2}>
							{NextDueDate}
						</ColoredTypography>
					</StyledDiv>
				</Grid>
				<PaddedDivider />
				<Grid container direction="row" justifyContent="space-between">
					<StyledDiv>
						<ColoredTypography variant="body2" color={colors.stratosLight2}>
							{I18n.EndDateLabel}
						</ColoredTypography>
					</StyledDiv>
					<StyledDiv>
						<ColoredTypography variant="body2" color={colors.stratosLight2}>
							{EndDate}
						</ColoredTypography>
					</StyledDiv>
				</Grid>
				<PaddedDivider />
				<Grid container direction="row" justifyContent="space-between">
					<StyledDiv>
						<ColoredTypography variant="body2" color={colors.stratosLight2}>
							{I18n.PaymentMethodLabel}
						</ColoredTypography>
					</StyledDiv>
					<StyledDiv>
						<ColoredTypography variant="body2" color={colors.stratosLight2}>
							{PaymentMethod}
						</ColoredTypography>
					</StyledDiv>
				</Grid>
				{PaymentMethodDetails && PaymentMethodDetails.Last4 && (
					<>
						<PaddedDivider />
						<GridWithBottomMargin container direction="row" justifyContent="space-between">
							<StyledDiv>
								<ColoredTypography variant="body2" color={colors.stratosLight2}>
									{I18n.PaymentMethodDetailsLabel}
								</ColoredTypography>
							</StyledDiv>
							<StyledDiv>
								{PaymentMethodDetails.CardBrand === "visa" && <VisaLogo />}
								{PaymentMethodDetails.CardBrand === "mastercard" && <MastercardLogo />}
								<ColoredTypography
									style={{marginLeft: 15}}
									variant="body2"
									color={colors.stratosLight2}
								>
									•••• {PaymentMethodDetails.Last4}
								</ColoredTypography>
							</StyledDiv>
						</GridWithBottomMargin>
					</>
				)}
				<PaddedDivider />
				<Grid container direction="row" justifyContent="space-between">
					<StyledDiv>
						<ColoredTypography variant="body2" color={colors.stratosLight2}>
							{I18n.TotalPaymentAmountLabel}
						</ColoredTypography>
					</StyledDiv>
					<StyledDiv>
						<ColoredTypography variant="body2" color={colors.stratosLight2}>
							{TotalPaymentAmount}
						</ColoredTypography>
					</StyledDiv>
				</Grid>
				<PaddedDivider />
			</CardContentLessPadding>
		</GreyStyledCard>
	)
}

export {PaymentArrangementDetailsCard}
