import {paymentArrangementAPI} from "@indebted/api"
import {AmountInput} from "@indebted/components/AmountInput"
import {ErrorPage} from "@indebted/components/Error"
import {LoadingPage} from "@indebted/components/Loading"
import {Page} from "@indebted/components/Page"
import {TextToHTML} from "@indebted/components/TextToHTML"
import Grid from "@mui/material/Unstable_Grid2"
import {Typography, Button, Collapse} from "@mui/material"
import React, {useState, useEffect} from "react"
import {useParams} from "react-router"
import styled from "styled-components"
import {useNavigate} from "react-router-dom"
import {useNotification} from "@indebted/components/Notification"

import {nextPage} from "../Navigator"

import {InstalmentBreakdown, InstalmentBreakdownI18n} from "./InstalmentBreakdown"

const AmountSelectionPage = () => {
	const {id} = useParams()
	const [Component, setComponent] = useState(<LoadingPage />)
	useEffect(() => {
		paymentArrangementAPI
			.getConfigureInstalmentAmountPage({paymentArrangementID: id})
			.then((result: ConfigureInstalmentAmountPageResponseType) => {
				setComponent(<AmountSelection options={result} />)
			})
			.catch((error) => {
				setComponent(<ErrorPage message={error.Message} error={error} />)
			})
	}, [id])

	return Component
}

type AmountSelectionI18n = {
	Title: string
	AmountInputLabel: string
	AmountButtonLabel: string
	Header: string
	MinimumAmountErrorMessage: string
}

type ConfigureInstalmentAmountPageResponseType = {
	MinimumInstalmentAmount: number
	Currency: string
	OutstandingBalance: number
	I18n: {
		InstalmentBreakdown: InstalmentBreakdownI18n
		AmountSelection: AmountSelectionI18n
	}
}

function AmountSelection({options}: {options: ConfigureInstalmentAmountPageResponseType}) {
	const {id} = useParams()
	const navigate = useNavigate()
	const {notification} = useNotification()

	const onSubmit = () => {
		// track("PPAmountButtonClicked", {
		// 	SecureCode: secureCode,
		// })
		paymentArrangementAPI
			.configureInstalmentAmount({paymentArrangementID: id, instalmentAmount: amount * 100})
			.then((result) => {
				navigate(nextPage(result.Next, id))
			})
			.catch((error) => {
				notification.error(error.Message, 5000)
			})
	}

	const [amount, setAmount] = useState<number | undefined>()

	const [minimumAmountError, setMinimumAmountError] = useState(false)
	const [showInstalmentBreakdown, setShowInstalmentBreakdown] = useState(false)

	const minimumAmount = options.MinimumInstalmentAmount / 100
	const minimumAmountErrorMessage = (
		<TextToHTML
			style={{color: "#D7153A", fontSize: "0.85rem"}}
			component="span"
			text={options.I18n.AmountSelection.MinimumAmountErrorMessage}
		/>
	)
	//const frequency = I18n.AmountSelection.DisplayText

	useEffect(() => {
		const delayInputTimeoutId = setTimeout(() => {
			const error = amount != undefined && amount < minimumAmount
			setMinimumAmountError(error)
			setShowInstalmentBreakdown(!!(amount && !error))
		}, 750)
		return () => clearTimeout(delayInputTimeoutId)
	}, [amount, minimumAmount])

	//TODO: we cant support now because we don't have the secure code. We need to discuss with the team
	//useEffect(() => {
	//	track("PPAmountPageViewed", {
	//		SecureCode: secureCode,
	//	})
	//}, [secureCode])

	const handleAmountInput: React.ChangeEventHandler<HTMLInputElement> = ({target: {value}}) => {
		const numericValue = +value
		setAmount(!isNaN(numericValue) ? numericValue : 0)
	}

	const buttonDisabled = () => {
		return !amount || amount < minimumAmount
	}

	return (
		<Page padded>
			<Grid container direction="column" alignItems="stretch" padding={0} style={{width: "100%"}}>
				<Grid style={{paddingLeft: 0}}>
					<Typography variant="h6">{options.I18n.AmountSelection.Header}</Typography>
				</Grid>
				<Grid style={{paddingLeft: 0}}>
					<Typography>{options.I18n.AmountSelection.Title}</Typography>
				</Grid>

				<AmountGrid container direction="column" alignItems="stretch" spacing={5} padding={0}>
					<Grid>
						<AmountInput
							label={options.I18n.AmountSelection.AmountInputLabel}
							error={minimumAmountError}
							value={amount}
							currency={options.Currency}
							helperText={minimumAmountError ? minimumAmountErrorMessage : undefined}
							onChange={handleAmountInput}
							required
							autoFocus
						/>
					</Grid>
					<Collapse in={showInstalmentBreakdown && amount > 0} timeout="auto">
						<Grid>
							<InstalmentBreakdown
								instalmentBreakdownI18n={options.I18n.InstalmentBreakdown}
								currency={options.Currency}
								outstandingBalance={options.OutstandingBalance}
								amountInCents={(amount ?? 0) * 100}
							/>
						</Grid>
					</Collapse>
					<Grid>
						<Button fullWidth disabled={buttonDisabled()} onClick={onSubmit}>
							{options.I18n.AmountSelection.AmountButtonLabel}
						</Button>
					</Grid>
				</AmountGrid>
			</Grid>
		</Page>
	)
}

const AmountGrid = styled(Grid)`
	margin-top: 10px;
	text-align: center;
`
export {AmountSelectionPage}
