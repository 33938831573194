import React, {useState} from "react"
import {useNotification} from "@indebted/components/Notification"
import {LoadingPage} from "@indebted/components/Loading"

import {FormContent} from "./FormContent"
import {FormConfirmation} from "./FormConfirmation"
import {Api} from "./api"

function Form({
	stripe,
	clientSecret,
	onCollect,
	onSubmit,
	i18n,
	discountMessage,
	paymentMethodSelectionLink,
	requestESIGNConsent,
	esignConsent,
}) {
	const {notification} = useNotification()
	const [Component, setComponent] = useState(
		<FormContent
			onSubmit={submitCollect}
			i18n={i18n}
			discountMessage={discountMessage}
			name=""
			email=""
			paymentMethodSelectionLink={paymentMethodSelectionLink}
			requestESIGNConsent={requestESIGNConsent}
			esignConsent={esignConsent}
		/>,
	)

	function submitCollect(form) {
		setComponent(<LoadingPage />)
		const emailValidationRegex =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

		if (form.Name.trim() === "" || !form.Email.match(emailValidationRegex)) {
			notification.error(i18n.FormErrorsEmailNameValidation, 5000)
			return
		}

		onCollect(stripe, {
			expand: ["payment_method"],
			clientSecret: clientSecret,
			params: {
				payment_method_type: "us_bank_account",
				payment_method_data: {
					billing_details: {
						name: form.Name,
						email: form.Email,
					},
				},
			},
		})
			.then(({intent, error}) => {
				if (error) {
					return Promise.reject(error)
				}

				if (intent.status === "requires_payment_method") {
					return Promise.reject({message: "Instant verification failed or canceled."})
				}

				if (intent.status === "requires_confirmation") {
					const onConfirm = () => {
						setComponent(<LoadingPage />)

						onSubmit({
							stripeAPI: Api({stripeSDK: stripe}),
						}).catch(() => {
							setComponent(
								<FormContent
									onSubmit={submitCollect}
									i18n={i18n}
									discountMessage={discountMessage}
									name={form.Name}
									email={form.Email}
									paymentMethodSelectionLink={paymentMethodSelectionLink}
									requestESIGNConsent={requestESIGNConsent}
									esignConsent={esignConsent}
								/>,
							)
						})
					}

					setComponent(
						<FormConfirmation
							onSubmit={onConfirm}
							i18n={i18n}
							discountMessage={discountMessage}
							paymentMethod={intent.payment_method}
						/>,
					)

					return
				}

				return Promise.reject({message: "Instant verification failed or canceled."})
			})
			.catch((error) => {
				setComponent(
					<FormContent
						onSubmit={submitCollect}
						i18n={i18n}
						discountMessage={discountMessage}
						name={form.Name}
						email={form.Email}
						paymentMethodSelectionLink={paymentMethodSelectionLink}
						requestESIGNConsent={requestESIGNConsent}
						esignConsent={esignConsent}
					/>,
				)
				notification.error(error.message, 5000)
			})
	}

	return Component
}

export {Form}
