import React from "react"
import {ThemeProvider as MuiThemeProvider} from "@mui/material/styles"
import {ThemeProvider} from "styled-components"
import {CssBaseline} from "@mui/material"
import {BrowserRouter, Route, Routes} from "react-router-dom"
import {InDebtedCoRoute} from "@indebted/components/InDebtedCoRoute"
import {NotFoundPage} from "@indebted/components/Error"
import {theme} from "@indebted/theme"
import {Layout} from "@indebted/components/Layout"
import {NotificationProvider} from "@indebted/components/Notification"
import {PaymentArrangementRouter} from "@indebted/payment-arrangement"

import {OptOutPage} from "./OptOutPage"
import {TransactionalEmailOptOutPage} from "./TransactionalEmailOptOutPage"
import {NotificationOfAssignmentAcknowledgementPage} from "./NotificationOfAssignmentAcknowledgementPage"
import {GrantESIGNConsentPage} from "./GrantESIGNConsentPage"
import {PaymentPlanStatusWithRegionPage} from "./Pages/PaymentPlanStatusPage"
import {Pages} from "./Pages"

const App = () => (
	<MuiThemeProvider theme={theme}>
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<NotificationProvider>
				<Layout>
					<BrowserRouter>
						<AppRoutes />
					</BrowserRouter>
				</Layout>
			</NotificationProvider>
		</ThemeProvider>
	</MuiThemeProvider>
)

const AppRoutes = () => (
	<Routes>
		<Route element={<InDebtedCoRoute />} path="/" />

		<Route element={<OptOutPage />} path="/opt-out/:token/:channel/*" />
		<Route element={<TransactionalEmailOptOutPage />} path="/transactional-email-opt-out/:token" />
		<Route element={<NotificationOfAssignmentAcknowledgementPage />} path="/accounts/ack-noa" />
		<Route element={<GrantESIGNConsentPage />} path="customers/grant-esign-consent/*" />
		<Route element={<GrantESIGNConsentPage />} path="customers/:regionPrefix/grant-esign-consent/*" />
		<Route element={<PaymentPlanStatusWithRegionPage region={0} />} path="/0/payment-plan/:paymentPlanID" />
		<Route element={<PaymentPlanStatusWithRegionPage region={1} />} path="/1/payment-plan/:paymentPlanID" />
		<Route element={<PaymentPlanStatusWithRegionPage region={2} />} path="/2/payment-plan/:paymentPlanID" />
		<Route element={<PaymentArrangementRouter />} path="/:region/payment-arrangement/*" />
		<Route element={<Pages />} path="/:secureCode/*" />
		<Route element={<NotFoundPage />} path="*" />
	</Routes>
)

export {App, AppRoutes}
