import React from "react"
import {Page} from "@indebted/components/Page"
import {Typography} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import {StripeDebitCardForm} from "@indebted/stripe-payment-arrangement"

function DebitCardForm({
	i18n,
	locale,
	providerPublishableKey,
	hidePostalCode,
	acceptedCards,
	morePaymentMethodsAvailable,
	onSubmit,
}: Omit<React.ComponentProps<typeof StripeDebitCardForm>, "paymentMethodSelectionLink">) {
	return (
		<Page padded>
			<Grid container direction="column" alignItems="stretch" spacing={5} padding={0}>
				<Grid>
					<Typography variant="h6">{i18n.Header}</Typography>
				</Grid>
				<Grid>
					<Typography>{i18n.Title}</Typography>
				</Grid>

				<Grid>
					<StripeDebitCardForm
						i18n={i18n}
						locale={locale}
						providerPublishableKey={providerPublishableKey}
						hidePostalCode={hidePostalCode}
						acceptedCards={acceptedCards}
						morePaymentMethodsAvailable={morePaymentMethodsAvailable}
						onSubmit={onSubmit}
						paymentMethodSelectionLink={"/payment-selection"}
					/>
				</Grid>
			</Grid>
		</Page>
	)
}

export {DebitCardForm}
