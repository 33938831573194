import React, {useState} from "react"
import {Button, Typography} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import {useNavigate, useParams} from "react-router-dom"
import {track} from "@indebted/analytics"
import {paymentArrangementAPI} from "@indebted/api"
import {useNotification} from "@indebted/components/Notification"
import {nextPage} from "@indebted/payment-arrangement/Navigator"

function PaymentArrangementOptionLink({
	Title,
	Subtitle,
	ButtonLabel,
}: {
	Title: string
	Subtitle: string
	ButtonLabel: string
}) {
	const {secureCode} = useParams()
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()
	const {notification} = useNotification()

	const onCreatePaymentArrangementClick = ({secureCode}) => {
		setLoading(true)
		track("PaymentArrangementInitiateSetup", {
			SecureCode: secureCode,
		})

		return paymentArrangementAPI
			.initiateSetup({secureCode})
			.then((data) => {
				track("PaymentArrangementInitiateSetupSucceeded", {
					SecureCode: secureCode,
				})

				navigate(nextPage(data.Next, data.PaymentArrangementID))
			})
			.catch((error) => {
				track("PaymentArrangementInitiateSetupFailed", {
					SecureCode: secureCode,
				})
				notification.error(error.Message, 5000)

				setLoading(false)
				console.error(error)
			})
	}

	return (
		<Grid container direction="column" alignItems="stretch" spacing={4} style={{flex: 1}}>
			<Grid>
				<Typography variant="h6" align="left">
					{Title}
				</Typography>
			</Grid>
			<Grid>
				<Typography variant="body2" color="textSecondary" align="left">
					{Subtitle}
				</Typography>
			</Grid>
			<Grid>
				<Button
					fullWidth
					disableElevation
					disabled={loading}
					onClick={() => onCreatePaymentArrangementClick({secureCode})}
				>
					{ButtonLabel}
				</Button>
			</Grid>
		</Grid>
	)
}

export {PaymentArrangementOptionLink}
