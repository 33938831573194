import React from "react"
import {ClickAwayListener, IconButton, Tooltip, Typography} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import {colors} from "@indebted/theme"
import withStyles from "@mui/styles/withStyles"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"

import {ManagePageI18n, ManagePageType} from "../types"

import {
	ColoredTypography,
	ColoredTypographyWithBottomMargin,
	CurrencyTypography,
	GradientHeaderGrid,
	GridWithHorizontalPadding,
	PaidAmountTypography,
	StyledTypography,
} from "./styles"
import {PaymentArrangementDetailsCard} from "./PaymentArrangementDetailsCard"

const ManagePaymentArrangement = ({
	I18n,
	InstalmentAmount,
	Currency,
	Frequency,
	TotalPaymentAmount,
	PaymentMethod,
	NextDueDate,
	PaidAmount,
	EndDate,
}: ManagePageType) => {
	const [open, setOpen] = React.useState(false)
	const handleTooltipClose = () => {
		setOpen(false)
	}
	const handleTooltipOpen = () => {
		setOpen(true)
	}
	return (
		<Grid container direction="column" alignItems="center">
			<Grid container direction="column" alignItems="stretch" justifyContent="space-between">
				<ManagePaymentArrangementComponentHeaderSection I18n={I18n} />

				<GridWithHorizontalPadding container direction="column">
					<Grid container direction="row" justifyContent="space-between">
						<StyledTypography color={colors.stratosLight}>{I18n.AmountPaidLabel}</StyledTypography>
					</Grid>
					<Grid container direction="row" alignItems="flex-end">
						<PaidAmountTypography color={colors.stratos}>{PaidAmount}</PaidAmountTypography>
						<CurrencyTypography color={colors.stratos}>{Currency}</CurrencyTypography>
						<ClickAwayListener onClickAway={handleTooltipClose}>
							<StyledTooltip
								PopperProps={{
									disablePortal: true,
								}}
								arrow
								placement="top"
								onClose={handleTooltipClose}
								open={open}
								disableFocusListener
								disableHoverListener
								disableTouchListener
								title={
									<>
										<Typography color="inherit">{I18n.AmountPaidTooltip}</Typography>
									</>
								}
							>
								<IconButton
									style={{
										marginBottom: "-3px",
										marginLeft: "-6px",
									}}
									aria-label="close"
									onMouseOver={handleTooltipOpen}
									onMouseLeave={handleTooltipClose}
									onClick={handleTooltipOpen}
									size="large"
								>
									<InfoOutlinedIcon htmlColor="#020452" fontSize="small" />
								</IconButton>
							</StyledTooltip>
						</ClickAwayListener>
					</Grid>
					<Grid container direction="row" justifyContent="space-between">
						<ColoredTypography variant="body2" color={colors.stratosLight}>
							{I18n.AmountRemainingAndPaidInstalmentsText}
						</ColoredTypography>
					</Grid>
					<PaymentArrangementDetailsCard
						I18n={I18n}
						InstalmentAmount={InstalmentAmount}
						Frequency={Frequency}
						EndDate={EndDate}
						TotalPaymentAmount={TotalPaymentAmount}
						PaymentMethod={PaymentMethod}
						PaymentMethodDetails={{}}
						NextDueDate={NextDueDate}
					/>
					<ColoredTypography variant="body2" color={colors.stratosLight} style={{padding: "20px 5px 0px"}}>
						{I18n.EndDateDisclaimerText}
					</ColoredTypography>
				</GridWithHorizontalPadding>
			</Grid>
		</Grid>
	)
}

const ManagePaymentArrangementComponentHeaderSection = ({I18n}: {I18n: ManagePageI18n}) => {
	return (
		<GradientHeaderGrid padded container direction="column" align="left">
			<Grid style={{padding: "12px"}}>
				<ColoredTypographyWithBottomMargin variant="h6" color={colors.stratos} className="clarity-mask">
					{I18n.Title}
				</ColoredTypographyWithBottomMargin>
				<ColoredTypography variant="body2" color={colors.stratos} className="clarity-mask">
					{I18n.Message}
				</ColoredTypography>
			</Grid>
		</GradientHeaderGrid>
	)
}

const StyledTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: "#fff",
		color: "rgba(0, 0, 0, 0.87)",
		maxWidth: 220,
		fontSize: theme.typography.pxToRem(12),
		border: "1px solid lightgray",
		boxShadow: "10px 10px 10px lightgray;",
		margin: "0px",
	},
	arrow: {
		color: "#f5f5f9",
	},
}))(Tooltip)

export {ManagePaymentArrangement}
