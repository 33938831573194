import React from "react"
import {Page} from "@indebted/components/Page"
import {Button, Typography} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"

import {OXXOLogo} from "../../../packages/assets"

function PaymentVoucherOXXOOutstanding({Amount, I18n, OXXO}) {
	return (
		<Page>
			<Grid>
				<Grid container spacing={6} style={{paddingLeft: "20px", paddingRight: "20px", alignItems: "center"}}>
					<Grid>
						<Typography align="center" variant="h6">
							{I18n.PaymentVoucherOXXOOutstanding.Title}
						</Typography>
					</Grid>

					<Grid>
						<Typography>{I18n.PaymentVoucherOXXOOutstanding.Subtitle}</Typography>
					</Grid>
					<Grid style={{width: "100%"}}>
						<Grid
							container
							direction="column"
							spacing={4}
							style={{backgroundColor: "#EDEFF1", borderRadius: "8px", padding: "10px"}}
						>
							<Grid>
								<Grid container direction="row" justifyContent="space-between">
									<Grid>
										<Typography variant="h6">{Amount}</Typography>
									</Grid>
									<Grid>
										<OXXOLogo width="58px" />
									</Grid>
								</Grid>
							</Grid>
							<Grid>
								<Grid
									container
									direction="column"
									spacing={2}
									style={{
										backgroundColor: "white",
										paddingLeft: "8px",
										paddingRight: "8px",
										borderRadius: "4px",
									}}
								>
									<Grid>
										<Typography variant="subtitle2">
											{I18n.PaymentVoucherOXXOOutstanding.VoucherNumberText}
										</Typography>
									</Grid>
									<Grid>
										<Typography variant="" color="#626392">
											{OXXO.VoucherNumber.replace(/(.{4})/g, "$1 ")}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
							<Grid style={{paddingLeft: "5px", paddingRight: "5px"}}>
								<Button
									fullWidth={true}
									style={{padding: "5px"}}
									href={OXXO.VoucherURL}
									target="_blank"
								>
									{I18n.PaymentVoucherOXXOOutstanding.VoucherURLButtonText}
								</Button>
							</Grid>
						</Grid>
					</Grid>
					<Grid>
						<Typography>{I18n.PaymentVoucherOXXOOutstanding.Footer}</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Page>
	)
}

export {PaymentVoucherOXXOOutstanding}
