import {Stripe} from "@stripe/stripe-js"

function Api({form, elements, stripeSDK}: {form; elements; stripeSDK: Stripe}) {
	function confirmSetup(clientSecret: string) {
		return stripeSDK
			.createPaymentMethod({
				elements,
				params: {
					billing_details: {
						name: form.Name,
						email: form.Email,
					},
				},
			})
			.then((result) => {
				if (result.error) return Promise.reject(result.error)
			})
			.then((result) => {
				return Promise.all([
					result,
					stripeSDK
						.confirmSetup({
							elements,
							redirect: "if_required",
							clientSecret,
							confirmParams: {
								payment_method_data: {
									billing_details: {
										name: form.Name,
										email: form.Email,
									},
								},
							},
						})
						.then((result) => {
							if (result.error) return Promise.reject(result.error)
							return result
						}),
				])
			})
	}

	function createPaymentMethod() {
		return stripeSDK
			.createPaymentMethod({
				elements,
				params: {
					billing_details: {
						name: form.Name,
						email: form.Email,
					},
				},
			})
			.then((result) => {
				if (result.error) return Promise.reject(result.error)
				return result
			})
	}

	function createPaymentRequest(PaymentAmount, PaymentCurrency, PaymentCountry, Label) {
		return stripeSDK.paymentRequest({
			country: PaymentCountry,
			currency: PaymentCurrency.toLowerCase(),
			total: {
				label: Label,
				amount: PaymentAmount,
			},
			requestPayerName: true,
			requestPayerEmail: true,
		})
	}

	function confirmPayment(clientSecret) {
		return stripeSDK
			.createPaymentMethod({
				elements,
				params: {
					billing_details: {
						name: form.Name,
						email: form.Email,
					},
				},
			})
			.then((result) => {
				if (result.error) return Promise.reject(result.error)
				return result
			})
			.then(() => {
				return stripeSDK.confirmPayment({
					elements,
					redirect: "if_required",
					clientSecret,
					confirmParams: {
						payment_method_data: {
							billing_details: {
								name: form.Name,
								email: form.Email,
							},
						},
					},
				})
			})
			.then((result) => {
				if (result.error) return Promise.reject(result.error)
				return result
			})
	}

	return {createPaymentMethod, confirmSetup, confirmPayment, createPaymentRequest}
}

export {Api}
