import React from "react"
import styled from "styled-components"
import {List, ListItem, Divider} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"

import {PaymentInFullOptionLink} from "./PaymentInFullOptionLink"
import {PartialPaymentOptionLink} from "./PartialPaymentOptionLink"
import {PaymentPlanOptionLink} from "./PaymentPlanOptionLink"
import {SettlementInFullOptionLink} from "./SettlementInFullOptionLink"
import {SettlementPlanOptionLink} from "./SettlementPlanOptionLink"
import {PaymentArrangementOptionLink} from "./PaymentArrangementOptionLink"

function PaymentOptionLinks({PaymentOptions}) {
	return (
		<FullWidthList>
			{PaymentOptions.map((paymentOption) => (
				<div key={`paymentoption-${paymentOption.Type}`} data-testid={`paymentoption-${paymentOption.Type}`}>
					<Divider />

					<ListItem>
						<PaddedGrid spacing={5}>
							{
								{
									PaymentInFull: <PaymentInFullOptionLink {...paymentOption.Properties} />,
									PartialPayment: <PartialPaymentOptionLink {...paymentOption.Properties} />,
									SettlementInFull: <SettlementInFullOptionLink {...paymentOption.Properties} />,
									PaymentPlan: <PaymentPlanOptionLink {...paymentOption.Properties} />,
									SettlementPlan: <SettlementPlanOptionLink {...paymentOption.Properties} />,
									PaymentArrangement: <PaymentArrangementOptionLink {...paymentOption.Properties} />,
								}[paymentOption.Type]
							}
						</PaddedGrid>
					</ListItem>
				</div>
			))}

			<Divider />
		</FullWidthList>
	)
}

const FullWidthList = styled(List)`
	width: 100%;
`
const PaddedGrid = styled(Grid).attrs({container: true})`
	padding: ${(props) => props.theme.spacing(5)};
	flex: 1;
`

export {PaymentOptionLinks}
